import React, {Component} from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import './StarLabel.css';

class StarLabel extends Component {

    state = {
        changeText: false
    }

    changeText = () => {
        this.setState({changeText: !this.state.changeText}) ;
    }

    render() {
        return (
            <CSSTransition
                onExited={() => this.changeText()}
            >
                <p className='StarLabel' id={this.props.cssId}>
                    {this.state.changeText ? this.props.german : this.props.english}
                </p>
            </CSSTransition>
        );
    }
}

export default StarLabel;
