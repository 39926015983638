import React from 'react';

import SpaceBuilder from './containers/SpaceBuilder';
import Toolbar from "./components/Toolbar/Toolbar";


function App() {
    return (
        <>
            <Toolbar/>
            <SpaceBuilder/>
        </>
    );
}

export default App;
