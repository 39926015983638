import React from 'react';

import atCrisLogo from '../../assets/images/ATCRIS_Logo_RGB.svg';
import './Logo.css'

const logo = () => (
    <div className='Logo'>
        <img src={atCrisLogo} alt='AT-CRIS Consulting & Implementing'/>
    </div>
);

export default logo;
