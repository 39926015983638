import React, {Component} from 'react';

import Space from '../components/Space/Space';
import StarLabel from '../components/StarLabel/StarLabel';


class SpaceBuilder extends Component {

    render() {
        return (
            <>
                <Space/>
                <StarLabel
                    cssId='secret1'
                    english='Something is coming...'
                    german='Etwas kommt...'
                />
                <StarLabel
                    cssId='secret2'
                    english='Something new...'
                    german='Etwas neues'
                />

                <StarLabel
                    cssId='secret3'
                    english='Something wonderful...'
                    german='Etwas wunderbares'
                />
                <StarLabel
                    cssId='secret4'
                    english='Something amazing...'
                    german='Etwas erstaunliches'
                />
                <StarLabel
                    cssId='secret5'
                    english='Are you curious?'
                    german='Neugierig geworden?'
                />
            </>
        );
    }
}

export default SpaceBuilder;
