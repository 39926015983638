import React from 'react';
import Logo from "../Logo/Logo";
import './Toolbar.css';


const toolbar = () => (
    <header className='Toolbar'>
        <Logo/>
    </header>
);

export default toolbar;
