import React from 'react';

import spaceImage from '../../assets/images/sky-828648_1920.jpg';
import './Space.css';

const space = () => (
    <div className='Space' style={{backgroundImage: `url(${spaceImage})`}} />
);

export default space;
